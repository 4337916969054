import React, { useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Home from './screens/home/home';
import CV from './screens/cv/cv';
import { analytics } from './firebase/firebase-config';
import { logEvent } from 'firebase/analytics';

import './App.css';

function App() {

  const location = useLocation();

  useEffect(() => {
    // Log page view whenever the route changes
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  }, [location]);

  
  return (
    <div className="container my-5 fade-in">
      
      {/* Define Routes */}
      <Routes>
        
        {/* Home Route */}
        <Route path="/" element={<Home />} />

        {/* CV Route */}
        <Route path="/cv" element={<CV />} />

      </Routes>
    </div>
  );
}

export default App;
