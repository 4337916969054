// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCS3fLxsle7_jw2rMwgmnOE2e08myuUPOM",
  authDomain: "keval-io.firebaseapp.com",
  projectId: "keval-io",
  storageBucket: "keval-io.appspot.com",
  messagingSenderId: "254536923506",
  appId: "1:254536923506:web:24ba93705aaee80051c5eb",
  measurementId: "G-DPW9SD5M37"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);