import React from 'react';
import './home-styles.css';  // Assuming 'styles.css' has Home-specific styles
import { analytics } from '../../firebase/firebase-config';
import { logEvent } from 'firebase/analytics';
import { Helmet } from "react-helmet";

const Home = () => {

  // Generic event handler to handle clicks and log events
  const handleClick = (eventName, eventParams) => {
    return () => {
      logEvent(analytics, eventName, eventParams);
    };
  };


  return (
    <div className="row">
      <Helmet>
        <title>Keval's Portfolio</title>
        <meta name="description" content="Welcome to Keval's personal portfolio website." />
        <link rel="canonical" href="https://keval.io" />
      </Helmet>

      {/* Left Column: Profile Picture, Name, and Description */}
      <div className="col-md-5 profile-section text-center text-md-left">
        <img src="profile.png" alt="Keval Langalia" className="profile-pic mb-3" />
        <h1>Keval Langalia</h1>
        <p className="intro">Full-stack developer with a passion for building innovative software solutions. Experienced in everything Android (Mobile, Automotive, TV, Wearable) as well as proficient in iPhone and Windows applications.</p>
        <p className="intro">Developing software and products for over 10 years. Currently contributing to the future of Automotive at General Motors.</p>
      </div>

      {/* Right Column: Contributions, Blog, and Social Media Links */}
      <div className="col-md-5 ml-md-4 social-section text-center d-flex flex-column justify-content-end">
        {/* Contributions Section */}
        <section className="contributions my-4">
          <h2>Contributions</h2>
          <div className="row">
            <div className="col-6">
              <a href="https://www.gm.com/gm-brands" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'general_motors_link'})}>General Motors</a><br />
              <a href="https://www.cerence.com/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'cerence_link'})}>Cerence</a><br />
              <a href="https://rbglobal.com/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'ritchie_bros_link'})}>Ritchie Brothers</a><br />
              <a href="https://www.jiosaavn.com/corporate/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'jiosaavn_link'})}>JioSaavn</a>
            </div>
            <div className="col-6">
              <a href="https://www.jugnoo.in/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'jugnoo_link'})}>Jugnoo</a><br />
              <a href="https://www.jio.com/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'jio_link'})}>Reliance Jio</a><br />
              <a href="http://www.eptl.in/pt/cc/home" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'eProcurement_link'})}>e-Procurement Technologies</a><br />
              <a href="https://www.simform.com/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'simform_link'})}>Simform Solutions</a>
            </div>
          </div>
        </section>

        {/* Blog and Contact Section */}
        <section className="my-4">
          <p>Blog: <a href="https://modernandroid.dev/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'modern_android_link'})}>Modern Android Development <i className="fab fa-android"></i></a></p>
          <p>Online CV: <a href="/cv" onClick={handleClick('onClick', {page: 'home', element: 'view_cv_link'})}>View CV</a></p>
          <p>Contact: <a href="mailto:hello@keval.io" onClick={handleClick('onClick', {page: 'home', element: 'email_contact_link'})}>hello@keval.io</a></p>
        </section>

        {/* Social Media Links */}
        <section className="social-links my-4">
          <a href="https://www.linkedin.com/in/kevallangalia/" className="mx-2" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'linkedin_link'})}><i className="fab fa-linkedin fa-2x"></i></a>
          <a href="https://github.com/thekeval" className="mx-2" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'github_link'})}><i className="fab fa-github fa-2x"></i></a>
          <a href="https://x.com/kevallangalia" className="mx-2" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'x_twitter_link'})}><i className="fa-brands fa-x-twitter fa-2x"></i></a>
          <a href="https://www.instagram.com/kevallangalia" className="mx-2" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'instagram_link'})}><i className="fab fa-instagram fa-2x"></i></a>
          <a href="https://stackoverflow.com/users/2049623/keval-langalia" className="mx-2" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'home', element: 'stackoverflow_link'})}><i className="fab fa-stack-overflow fa-2x"></i></a>
        </section>
      </div>
    </div>
  );
};

export default Home;
