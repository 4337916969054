import React from 'react';
import { analytics } from '../../firebase/firebase-config';
import { logEvent } from 'firebase/analytics';
import { Helmet } from "react-helmet";

// Bootstrap and FontAwesome CSS via npm
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Bootstrap JS and dependencies via npm
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import local scss file
import './scss/orbit-1.scss';   // scss = sassy css (advanced css with variables, nesting, etc.)

// Import local assets
import profileImage from '../../assets/images/profile.png';
import resume from '../../assets/download/keval_langalia_resume_v7_1.pdf';


// Generic event handler to handle clicks and log events
const handleClick = (eventName, eventParams) => {
  return () => {
    logEvent(analytics, eventName, eventParams);
  };
};

const CV = () => (
  
  <div className="wrapper">
    <Helmet>
        <title>Keval's Resume and CV</title>
        <meta name="description" content="View Keval's CV and professional experience." />
        <link rel="canonical" href="https://keval.io/cv" />
    </Helmet>
    <div className="sidebar-wrapper">
      <div className="profile-container">
        <img className="profile" src={profileImage} alt="Profile picture of Keval Langalia" />
        <h1 className="name">Keval Langalia</h1>
        <h3 className="tagline">Sr. Software Engineer</h3>
      </div>

      <div className="contact-container container-block">
        <ul className="list-unstyled contact-list">
          <li className="email">
            <i className="fas fa-map-marker"></i>
            <a className="social-links" href="https://goo.gl/maps/zfUdoVxxKt7dcZMaA" onClick={handleClick('onClick', {page: 'cv', element: 'current_city_link'})}>Toronto, Canada</a>
          </li>
          <li className="email">
            <i className="fas fa-envelope"></i>
            <a className="social-links" href="mailto:hello@keval.io" onClick={handleClick('onClick', {page: 'cv', element: 'email_link'})}>hello@keval.io</a>
          </li>
          <li className="website">
            <i className="fas fa-globe"></i>
            <a className="social-links" href="https://keval.io" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'website_link'})}>keval.io</a>
          </li>
          <li className="website">
            <i className="fab fa-android"></i>
            <a className="social-links" href="https://modernandroid.dev" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'modern_android_link'})}>modernAndroid.dev</a>
          </li>
          <li className="linkedin">
            <i className="fab fa-linkedin-in"></i>
            <a className="social-links" href="https://www.linkedin.com/in/kevallangalia/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'linkedin_link'})}>linkedin.com/in/kevallangalia</a>
          </li>
          <li className="github">
            <i className="fab fa-github"></i>
            <a className="social-links" href="https://github.com/thekeval" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'github_link'})}>github.com/thekeval</a>
          </li>
          <li className="twitter">
            <i className="fa-brands fa-x-twitter"></i>
            <a className="social-links" href="https://x.com/kevallangalia" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'x_twitter_link'})}>@kevallangalia</a>
          </li>
          <li className="stack-overflow">
            <i className="fab fa-stack-overflow"></i>
            <a className="social-links" href="https://stackoverflow.com/users/2049623/keval-langalia" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'stackoverflow_link'})}>StackOverflow Profile</a>
          </li>
          <li className="stack-overflow">
            <i className="fas fa-download"></i>
            <a className="social-links" href={resume} download onClick={handleClick('onClick', {page: 'cv', element: 'download_resume_link'})}>Download Resume</a>
          </li>
        </ul>
      </div>

      <div className="education-container container-block">
        <h2 className="container-block-title">Education</h2>
        <div className="item">
          <h4 className="degree">Post Graduation in Mobile Application Development</h4>
          <h5 className="meta">Lambton College in Toronto</h5>
          <div className="time">2021 - 2022</div>
        </div>
        <div className="item">
          <h4 className="degree">Bachelor of Computer Engineering</h4>
          <h5 className="meta">Gujarat Technological University</h5>
          <div className="time">2009 - 2013</div>
        </div>
      </div>

      <div className="education-container container-block">
        <h2 className="container-block-title">Developer Communities</h2>
        <div className="item">
          <h4 className="degree">
            <a href="https://developers.google.com/community/gdg" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'gdg_link'})}>Google Developer Groups</a>
          </h4>
          <div className="time">GDG Ahmedabad</div>
          <div className="time">GDG Gandhinagar</div>
          <div className="time">November 2015 - present</div>
        </div>
        <div className="item">
          <h4 className="degree">
            <a href="https://studentpartners.microsoft.com/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'ms_student_partner_link'})}>Microsoft Student Partner</a>
          </h4>
          <div className="time">December 2012 - April 2014</div>
        </div>
      </div>

      <div className="languages-container container-block">
        <h2 className="container-block-title">Languages</h2>
        <ul className="list-unstyled interests-list">
          <li>English <span className="lang-desc">(Professional)</span></li>
          <li>Hindi <span className="lang-desc">(Professional)</span></li>
          <li>Gujarati <span className="lang-desc">(Native)</span></li>
        </ul>
      </div>

      <div className="interests-container container-block">
        <h2 className="container-block-title">Interests</h2>
        <ul className="list-unstyled interests-list">
          <li>Artificial Intelligence</li>
          <li>Astrophysics & Cosmology</li>
          <li>Spirituality</li>
          <li>Sci-Fi Movies & Books</li>
          <li>Video Games</li>
          <li>Travelling & Exploring</li>
        </ul>
      </div>
    </div>

    <div className="main-wrapper">
      <section className="section summary-section">
        <h2 className="section-title">
          <span className="icon-holder">
            <i className="fas fa-user icon-margin"></i>
          </span>
          <span className="section-main-title">Career Profile</span>
        </h2>
        <div className="summary">
          <p>Over 10 years of work experience in different industries, focused on Android application development for Mobile, Automotive, TV, Wearable, and Cross-Platform using Kotlin and Java.</p>
          <p>Fluent in using Android Jetpack Libraries, including life-cycle components, Material Design components, Navigation, Dependency Injection (Dagger Hilt), SQLite Room, data binding, and Coroutines.</p>
          <p>Good experience in product conceptualization, POC, prototyping, wireframing software, database design, design patterns, and designing system architecture.</p>
          <p>I believe the art is in the ability to learn!</p>
        </div>
      </section>

      <section className="skills-section section">
        <h2 className="section-title">
          <span className="icon-holder">
            <i className="fas fa-rocket icon-margin"></i>
          </span>
          <span className="section-main-title">Technical Competencies</span>
        </h2>
        <div className="skillset">
          <ul>
            <li>
              <div><span className="skill-title">Platforms:</span> Android (Mobile, Automotive OS, TV, Wearable), QNX RTOS & Cross Platform using React Native and QT</div>
            </li>
          </ul>
          <ul>
            <li>
              <div><span className="skill-title">Programming Languages and Build Tools:</span> Kotlin, Java, C++, JavaScript, C#, Python, Gradle, Jenkins, CMake</div>
            </li>
          </ul>
          <ul>
            <li>
              <div><span className="skill-title">Android Libraries:</span> Jetpack Compose, Retrofit, Dagger Hilt, Gson, Glide, Mockito, Robolectric</div>
            </li>
          </ul>
          <ul>
            <li>
              <div>Implementation of REST API and GraphQL queries (AWS Amplify - AppSync)</div>
            </li>
          </ul>
          <ul>
            <li>
              <div>Experienced in using <span className="skill-title">GIT, Bitbucket, SVN</span> for version control and <span className="skill-title">Trello, Asana</span> for task tracking</div>
            </li>
          </ul>
          <ul>
            <li>
              <div><span className="skill-title">Agile Software Development Methodology</span> with daily Scrum, Sprint planning, Cadence based planning</div>
            </li>
          </ul>
          <ul>
            <li>
              <div>Experienced in implementing <span className="skill-title">MVP, MVI & MVVM architecture</span> and various <span className="skill-title">Design Patterns</span></div>
            </li>
          </ul>
        </div>
      </section>

      <section className="section experiences-section">
        <h2 className="section-title">
          <span className="icon-holder">
            <i className="fas fa-briefcase icon-margin"></i>
          </span>
          <span className="section-main-title">Experiences</span>
        </h2>
        <div className="item">
          <div className="meta">
            <div className="upper-row">
              <h3 className="job-title">Senior Software Engineer (Contract)</h3>
              <div className="time">October 2022 - Present</div>
            </div>
            <div className="company">
              <a href="https://www.gm.com/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'general_motors_link'})}>General Motors</a> (via <a href="https://www.teksystems.ca" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'teksystems_link'})}>TEKSystems Canada</a>) - Toronto, CA
            </div>
          </div>
          <div className="details">
            <ul>
              <li>Contributed to multiple projects under Vehicle Infotainment, Virtual Controls, and Climate Control Systems such as Apple CarPlay service, HVAC App, Vehicle Status App, Performance App, and Energy (Charging for EV) App.</li>
              <li>CarPlay Service: Worked on both wired and wireless CarPlay, KPI for connectivity, implemented iAP2 protocol for FastConnect, Cinemo SDK Integration, Media playback, Audio ducking for multiple media sources, and Lane Guidance for map navigation.</li>
              <li>Took shared ownership of Voice Assistance (VA) domain, managing multiple VA such as Siri and native VA. Contributed as domain expert for Connectivity flow of wired and wireless CarPlay. Languages used include Java, Kotlin, and C++ for services, and Python for scripting.</li>
              <li>Worked with ATS utility and Beagle box to collect logs. Resolved approximately 117 defects by analyzing large logs using various scripts and text analysis tools, including ANRs and black screen issues.</li>
              <li>RTOS Apps with QT: Contributed to the development of two RTOS apps, Virtual Controls (VC) and Climate Controls (HVAC), targeted to QNX using QT platform SDK. Took feature ownership of Dome Light controls in VC and Defrost in HVAC.</li>
              <li>Integrated CAN signal handling for owned features while using in-house built tool to mock the CAN signals for testing. This project was partially developed in RxCPP and Qt libraries, using CMake for build tools.</li>
              <li>Contributed to developing a tool to automate the flashing process for different builds to VCU and push various calibrations according to the selected display. Also contributed to CAN signal integration in a vehicle simulator.</li>
              <li>Charging App: Worked on various system properties for charging features such as active charging, schedule charging, delay charging, and DWL (Driving With Load) charging. Contributed to developing card view UI for the charging app, writing Gradle scripts to automate view navigation, and recording video through shell scripts.</li>
              <li>Worked on localization for RTL languages and adding mock scripts for testing and verification. This project used Kotlin, Groovy for mock scripts, Dagger for DI, and shell scripts for testing automation.</li>
            </ul>
          </div>
        </div>

        <div className="item">
          <div className="meta">
            <div className="upper-row">
              <h3 className="job-title">Software Engineer (Contract)</h3>
              <div className="time">April 2022 - October 2022</div>
            </div>
            <div className="company">
              <a href="https://www.cerence.com/" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'cerence_link'})}>Cerence</a> (via <a href="https://www.lynxtechgroup.com" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'lynxtech_link'})}>LynxTech Group LLC</a>) - Toronto, CA
            </div>
          </div>
          <div className="details">
            <ul>
              <li>Worked on Voice Assistance Android Automotive SDK to be integrated by OEM.</li>
              <li>Implemented voice recognition engine powered by an in-house AI library and handled communication between VUI and GUI for command and intent generation.</li>
              <li>Owned complete responsibility of the Media Domain, which includes Media playback, user favorites, personalization, preferences, and music provider Spotify.</li>
              <li>Worked on Unit testing for all domains such as Media, Radio, Reminder, Memo, Messaging, etc. using JUnit and Mockito with the help of Dagger2 (DI).</li>
            </ul>
          </div>
        </div>
        <div   className="item">
                    <div   className="meta">
                        <div   className="upper-row">
                            <h3   className="job-title">Android Engineer (Contract)</h3>
                            <div   className="time">November 2021 - December 2021</div>
                        </div>
                        <div   className="company"><a href="https://rbglobal.com/" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'ritchi_bros_link'})}>Ritchi Brothers</a> (via <a href="https://www.accionlabs.com" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'accionlabs_link'})}>Accion Labs</a>) - Toronto, CA</div>
                    </div>
                    <div   className="details">
                        <ul>
                            <li>Built dynamic Product Details Page for assets to put out for auctions, the page includes server-controlled data and modules. Used content-stack to organize localized string resources.</li>
                            <li>Implemented AWS Amplify library for fetching data through GraphQL queries and update with mutations. Used subscription to enable real-time data integration in Mobile app.</li>
                        </ul>
                    </div>
                </div>

                <div   className="item">
                    <div   className="meta">
                        <div   className="upper-row">
                            <h3   className="job-title">Consultant Mobile Engineer</h3>
                            <div   className="time">March 2017 - May 2020</div>
                        </div>
                        <div   className="company"><a href="https://www.jiosaavn.com/corporate" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'jiosaavn_link'})}>JioSaavn (Saavn Media Pvt Ltd)</a> - Mumbai, India</div>
                    </div> 
                    <div   className="details">
                        <ul>
                            <li>Built Lyrics feature for android mobile and TV app in sync with music including annotations and timestamp in Kotlin language using Kotlin Coroutines, RecyclerView, custom views. Wrote Unit tests for this feature using JUnit and Espresso.</li>
                            <li>Worked on user-profile personalization which includes developing modular home screen based on API call response. developed in Kotlin using Retrofit, Gson, Room database and repository design pattern.</li>
                            <li>Developed Android TV application for JioSaavn using Leanback library. Managed different versions for various manufacturers such as Jio Set-top-box, Amazon Firestick and OnePlus TV. This was developed in Java and used navigation library, databinding, compose animations etc.</li>
                            <li>Migrated existing code to build Android Automotive app (car system) and SDK to be integrated in third party apps ensuring flowless execution and smooth user experience. Accountable for managing notifications catalog and sending push notifications to targeted users through Firebase Cloud Messaging service.</li>
                            <li>Worked on optimizing app performance by monitoring stats from Android Profiler in Android Studio. Used Firebase Crashlitics to identify and eliminate crashes and bugs. Published multiple versions of the app on Play store.</li>
                        </ul>
                    </div>  
                </div>  
                
                <div   className="item">
                    <div   className="meta">
                        <div   className="upper-row">
                            <h3   className="job-title">Software Engineer</h3>
                            <div   className="time">March 2016 - March 2017</div>
                        </div>  
                        <div   className="company"><a href="https://www.jugnoo.in/" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'jugnoo_link'})}>Jugnoo (Socomo Technologies)</a> - Chandigarh, India</div>
                    </div> 
                    <div   className="details">
                        <ul>
                            <li>Ride booking App: Worked on drawing path on map and animation of markers through GeoPath - series of latitude longitude. This is to show the route on map and vehicles moving on road. Developed using Java with Google Map libraries.</li>
                            <li>Developed feature to show multiple vehicle types as available rides based on API call response. used Retrofit library, Gson and Android material design. Used testing libraries such as JUnit & Robolectric to replicate scenarios.</li>
                            <li>Driver App: Collaborated with management team to develop driver onboarding app which lets driver register on the platform and get alerts for ride requests. Driver app was built using Kotlin and various API such as Alarm manager, draw over other app, deeplinks integration, property animations.</li>
                            <li>POS (point of sale) App: Designed and developed initial versions of application for associated restaurants and grocery stores which directly integrate to the delivery system of the company. This app was built using Java and wrote unit tests using espresso.</li>
                        </ul>
                    </div>  
                </div>  
                
                <div   className="item">
                    <div   className="meta">
                        <div   className="upper-row">
                            <h3   className="job-title">Brand Experiences Manager</h3>
                            <div   className="time">October 2015 - March 2016</div>
                        </div>  
                        <div   className="company"><a href="https://www.jio.com" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'reliance_jio_link'})}>Reliance Jio Infocomm</a> - Mumbai, India</div>
                    </div> 
                    <div   className="details">
                        <ul>
                            <li>Developed wireframe and prototypes of multiple applications including JioMoney, JioCloud, JioCinema and MyJio. These prototypes were developed natively on Android Studio.</li>
                            <li>Worked on various prototyping and wireframing tools like Axure, Sketch, Adobe Photoshop, and Adobe XD. Used Titanium Appcelerator tool to develop cross-platform prototypes.</li>
                        </ul>
                    </div>  
                </div>  

                <div   className="item">
                    <div   className="meta">
                        <div   className="upper-row">
                            <h3   className="job-title">Software Engineer</h3>
                            <div   className="time">October 2014 - October 2015</div>
                        </div>  
                        <div   className="company"><a href="http://www.eptl.in/pt/cc/home" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'eprocurement_link'})}>eProcurement Technologies Pvt Ltd</a> - Ahmedabad, India</div>
                    </div> 
                    <div   className="details">
                        <ul>
                            <li>Contributed in development of company's two major products TenderTiger and AuctionTiger for Windows platforms using C# and XAML. Used Windows Runtime framework to maintain single code base for the Universal Windows Applications.</li>
                        </ul>
                    </div>  
                </div>  

                <div   className="item">
                    <div   className="meta">
                        <div   className="upper-row">
                            <h3   className="job-title">Software Engineer</h3>
                            <div   className="time">February 2013 - October 2014</div>
                        </div>  
                        <div   className="company"><a href="https://www.simform.com/" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'simform_link'})}>Simform Solutions Pvt Ltd</a> - Ahmedabad, India</div>
                    </div> 
                    <div   className="details">
                        <ul>
                            <li>As the company being service based company, I've worked on several projects in many different domains and developed Windows Phone, Windows 8 & Universal Windows Platform (UWP) applications.</li>
                            <li>Some of the various domains I've worked on are Compression technology, Image & Video Processing technologies (including video generation using frames), PDF rendering (PDF reader app), Activity Tracking System (i.e. employee tracking systems for windows desktop users), 3D model rendering in Windows desktop platform, native game development in UWP.</li>
                            <li>Windows platforms and technologies I've used here are, UWP, Windows Phone 8 and 8.1, Windows Desktop with WPF and WCF services, Java Swing, Core Java, .NET framework, C#.net, XAML and Windows Azure.</li>
                        </ul>
                    </div>  
                </div>  

        {/* Add more experience items here as needed */}
      </section>

      <section className="section projects-section">
        <h2 className="section-title">
          <span className="icon-holder">
            <i className="fas fa-archive icon-margin"></i>
          </span>
          <span className="section-main-title">Projects</span>
        </h2>
        <div className="intro">
          <p>Here are some of the Projects I did apart from my full-time work.</p>
        </div>
        <div className="item">
          <span className="project-title">
            <a href="https://apps.microsoft.com/detail/9nblggh31bwl" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'mec_india_project_link'})}>MEC India</a>
          </span>
          <ul>
            <li>Cross-Platform application for Android, iPhone & Windows using Xamarin</li>
            <li>Massively Empowered Classroom was an initiative by Microsoft Research, to bring education courses online.</li>
          </ul>
        </div>
        <div className="item">
          <span className="project-title">
            <a href="https://play.google.com/store/apps/details?id=com.gensuite.onthego" target="_blank" rel="noopener noreferrer" onClick={handleClick('onClick', {page: 'cv', element: 'gensuite_mobile_poroject_link'})}>Gensuite Mobile</a>
          </span>
          <ul>
            <li>Native Android App - in-depth use of WebView with JavaScript</li>
            <li>EHS software solutions for a massive corporation, project through UpWork, for Gensuite LLC.</li>
          </ul>
        </div>
        <div   className="item">
                    <span   className="project-title"><a href="https://www.videoshop.net/" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'videoshop_project_link'})}>Videoshop</a>
                    </span> : 
                    <ul>
                        <li>Native UWP application using C++ lib for Video editing</li>
                        <li>Video generation and editing API in C++ and C# using Frames on windows platform.</li>
                    </ul>
                </div>  

                <div   className="item">
                    <span   className="project-title"><a href="https://apps.microsoft.com/detail/9wzdncrfjb33" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: '8zip_project_link'})}>8 Zip</a>
                    </span> : 
                    <ul>
                        <li>Native UWP application using compression technology.</li>
                        <li>open and extract any kind of compressed files like RAR, ZIP, 7zip, 7z, ZipX, Iso, Cab</li>
                    </ul>
                </div>  

                <div   className="item">
                    <span   className="project-title"><a href="https://github.com/theKeval/PDFReader" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'pdfreader_project_link'})}>PDF Reader</a>
                    </span> : 
                    <ul>
                        <li>Native UWP app to view and edit (annotate) PDF documents.</li>
                        <li>Individually developed this product to learn PDF operations and Iniking API.</li>
                    </ul>
                </div>  

                <div   className="item">
                    <span   className="project-title"><a href="https://apps.microsoft.com/detail/9wzdncrdctb8" target="_blank" onClick={handleClick('onClick', {page: 'cv', element: 'jobaka_project_link'})}>Jo Baka</a>
                    </span> : 
                    <ul>
                        <li>Native Windows 8 Mobile application to create and share meme.</li>
                        <li>This application uses image generation/processing APIs</li>
                    </ul>
                </div>  
        {/* Add more project items here as needed */}
      </section>
    </div>
  </div>
);

export default CV;
