import { analytics } from './firebase/firebase-config'; // Import analytics to initialize it
import { logEvent } from 'firebase/analytics'; // Import logEvent to log events


const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);

      // Optionally log performance metrics to Firebase Analytics
      getCLS((metric) => logEvent(analytics, 'CLS', { value: metric.value })); // Cumulative Layout Shift
      getFID((metric) => logEvent(analytics, 'FID', { value: metric.value })); // First Input Delay
      getFCP((metric) => logEvent(analytics, 'FCP', { value: metric.value })); // First Contentful Paint
      getLCP((metric) => logEvent(analytics, 'LCP', { value: metric.value })); // Largest Contentful Paint
      getTTFB((metric) => logEvent(analytics, 'TTFB', { value: metric.value })); // Time to First Byte
    });
  }
};

export default reportWebVitals;
